import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const HeadlineStyled = styled.h3`
    color: ${(props) => props.theme.colors.texts.capeCod};
    font: 1.5rem ${(props) => props.theme.fonts[0]};
    font-weight: 500;
    letter-spacing: 0;
    line-height: 34px;
    margin-bottom:32px;
    :after{
        display: block;
        content: ' ';
        width: 76px;
        margin-top: 21px;
        border-bottom: 3px solid
            ${(props) => props.theme.colors.secondary};
            @media screen and (max-width: 768px) {
               width: 55.47px;
            }
    }
    @media screen and (max-width: 768px) {
       font: 1.25rem ${(props) => props.theme.fonts[0]};
    }
`
export default function Headline({
    headlineText
}) {
    return (
        <HeadlineStyled >
            {headlineText}
        </HeadlineStyled>
    )
}
Headline.propTypes = {
    headlineText: PropTypes.string.isRequired
};

