export const miniCardParams = () => {
    return [
        {
            name: 'bgImage',
            type: 'string',
            required: 'true',
            default: 'n/a',
            description: 'Image to be displayed',
        },
        {
            name: 'stat',
            type: 'string',
            required: 'true',
            default: 'n/a',
            description: 'Number of the things being spoken about',
        },
        {
            name: 'title',
            type: 'string',
            required: 'true',
            default: 'n/a',
            description: 'Description of the thing being presented',
        },
    ];
};
