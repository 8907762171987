import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';

import CloseAccordion from './ui/CloseAccordion';
import OpenAccordion from './ui/OpenAccordion';

const AccordionStyled = styled.article`
    display: grid;
    grid-template-columns: ${(props) =>
        props.hasImage ? '100px 1fr 30px' : '1fr 30px'};
    grid-template-rows: ${(props) => (props.isOpen ? '123px 1fr' : '123px')};
    grid-gap: 46px;
    width: 100%;
    padding: 26px 46px;
    background-color: ${(props) =>
        !props.isPrimaryType
            ? props.theme.colors.backgrounds.porcelain
            : props.hasFocus
            ? '#fafafa'
            : props.theme.colors.white};
    border-radius: ${(props) => (!props.isPrimaryType ? '2px' : 0)};
    box-sizing: border-box;
    border-bottom: ${(props) =>
        !props.isPrimaryType ? '0px' : '2px solid #bebebe;'};
    margin-top: ${(props) => (!props.isPrimaryType ? '33px;' : '0px;')};

    @media screen and (min-width: 768px) {
        grid-template-columns: ${(props) =>
            props.hasImage ? '100px 1fr 53px' : '1fr 53px'};
    }

    .accordion__image {
        width: 100px;
        height: auto;
        margin-right: 46px;
    }

    .accordion__header {
        align-self: center;

        &__title {
            display: inline;
            font-size: 1.25rem;
            line-height: 1.75rem;
            font-family: ${(props) => props.theme.fonts[0]}, sans-serif;
            color: ${(props) => props.theme.colors.texts.capeCod};
            box-sizing: border-box;

            @media screen and (min-width: 768px) {
                font-size: 1.5rem;
                line-height: 1.875rem;
                letter-spacing: 0.013125rem;
            }
        }

        span {
            display: block;
            width: 0px;
            height: 3px;
            margin-top: 2px;
            background-color: ${(props) => props.theme.colors.secondary};
            opacity: 0;
            transition: all 0.25s ease-out;
        }

        span.focus {
            width: 150px;
            opacity: 1;
            transition: all 0.25s ease-in;
        }

        &__subTitle {
            margin-top: 5px;
            font: 700 0.8125rem ${(props) => props.theme.fonts[0]}, sans-serif;
            line-height: 1.5rem;
            letter-spacing: 0.075rem;
            color: ${(props) => props.theme.colors.texts.boulderGrey};
            text-transform: uppercase;
        }
    }

    .accordion__body {
        grid-column: ${(props) => (props.hasImage ? '1/4' : '1/3')};
        margin-top: ${(props) => (!props.isPrimaryType ? '-31px' : '-64px')};
        font: 1.125rem ${(props) => props.theme.fonts[1]}, serif;
        line-height: 2.375rem;
        color: ${(props) =>
            props.isPrimaryType ? props.theme.colors.shipGrey : '#686a6c'};

        @media screen and (min-width: 768px) {
            grid-column: ${(props) => (props.hasImage ? '2/3' : '1/2')};
            margin-top: ${(props) => (props.isPrimaryType ? '-54px' : '-68px')};
            font: 1.25rem ${(props) => props.theme.fonts[1]}, serif;
            line-height: 2.625rem;
        }
    }

    .open-accordion,
    .close-accordion {
        align-self: flex-start;
        width: 30px;
        height: 30px;
        min-width: 30px;
        margin-top: 35px;

        @media screen and (min-width: 768px) {
            width: 53px;
            height: 53px;
            min-width: 53px;
        }
    }
`;

export default function Accordion({
    copy,
    imgObj,
    isPrimaryType,
    subTitle,
    title,
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [hasFocus, setHasFocus] = useState(false);
    const handleFocus = (mouseEvent) => {
        mouseEvent === 'enter' ? setHasFocus(true) : setHasFocus(false);
    };

    useEffect(() => {}, [hasFocus]);

    return (
        <AccordionStyled
            hasFocus={hasFocus}
            hasImage={imgObj ? true : false}
            isOpen={isOpen}
            isPrimaryType={isPrimaryType}
        >
            {imgObj ? (
                <img
                    className="accordion__image"
                    src={imgObj.imgSrc}
                    width="120"
                    height="148"
                    alt={imgObj.imgAlt}
                    title={imgObj.imgTitle}
                />
            ) : null}
            <div className="accordion__header">
                <p className="accordion__header__title">{title}</p>
                {isPrimaryType ? (
                    <span
                        className={`name-border ${hasFocus ? 'focus' : null}`}
                    ></span>
                ) : null}
                {subTitle ? (
                    <p className="accordion__header__subTitle">{subTitle}</p>
                ) : null}
            </div>
            {isOpen ? (
                <CloseAccordion
                    clicked={() => setIsOpen(false)}
                    mouseEnter={() => handleFocus('enter')}
                    mouseLeave={() => handleFocus('exit')}
                />
            ) : (
                <OpenAccordion
                    clicked={() => setIsOpen(true)}
                    mouseEnter={(e) => handleFocus('enter')}
                    mouseLeave={(e) => handleFocus('exit')}
                />
            )}
            <AnimatePresence>
                {isOpen && (
                    <motion.p
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0 }}
                        className="accordion__body"
                        dangerouslySetInnerHTML={{ __html: copy }}
                    />
                )}
            </AnimatePresence>
        </AccordionStyled>
    );
}

Accordion.propTypes = {
    copy: PropTypes.string.isRequired,
    imgObj: PropTypes.shape({
        imgAlt: PropTypes.string.isRequired,
        imgSrc: PropTypes.string.isRequired,
        imgTitle: PropTypes.string.isRequired,
    }),
    isPrimaryType: PropTypes.bool,
    subTitle: PropTypes.string,
    title: PropTypes.string.isRequired,
};

Accordion.defaultProps = {
    isPrimaryType: true,
};
