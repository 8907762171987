export const subMenuParams = () => {
    return [
        {
            name: 'subMenuLinks',
            type: 'obj',
            required: 'true',
            default: 'n/a',
            description: 'Used on About us to navigate through various sections',
        },
        {
            name: 'window',
            type: 'obj',
            required: 'true',
            default: 'n/a',
            description: 'Detect window size for resolution specific logic',
        },
    ]

}