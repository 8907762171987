import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const PullQuoteBoxStyled = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    border: 1px solid #DBDBDB;
    background-color: #FFFFFF;
    padding: 25px 41px 19px 31px;
    font-family: ${(props) => props.theme.fonts[1]}, sans-serif;
    max-width: 868px;
    .quote-title {
        min-width: 256px;
        color: #323333;
        font: 1.5rem ${(props) => props.theme.fonts[0]}, sans-serif;        
        letter-spacing: 0.03px;
        line-height: 2.25rem;
        margin-bottom: 12px;
    }

    .quote-content {
        min-width: 256px;
        color: ${(props) => props.theme.colors.texts.shipGrey};        
        font-family: 1.125rem ${(props) => props.theme.fonts[1]}, sans-serif;        
        letter-spacing: 0;
        line-height: 2.375rem;
    }

    @media screen and (min-width: 768px) {
        padding: 64px 41px 58px 67px;
        .quote-title {
            height: 40px;
            font-size: 1.875rem;
            margin-bottom: 18px;
        }
    
        .quote-content {        
            font-size: 1.25rem;
            letter-spacing: 0;
            line-height: 2.625rem;
        } 
    }

    @media screen and (min-width: 1024px) {
    }
`;

export default function PullQuoteBox({ quoteTitle, quoteContent }) {
    return (
        <PullQuoteBoxStyled>
            <div className="quote-title">{quoteTitle}</div>
            <p className="quote-content">{quoteContent}</p>
        </PullQuoteBoxStyled>
    );
}

PullQuoteBox.propTypes = {
    quoteTitle: PropTypes.string.isRequired,
    quoteContent: PropTypes.string.isRequired,
};
