import React, { Component } from 'react';
import Theme from '../components/Theme';
import { withSize } from 'react-sizeme';

import GlobalStyles from '../components/global-styles';
import PatternLibraryStyled from '../components/pageStyles/pattern-library-styles';

import Colors from '../components/patternLibrary/Colors';
import Components from '../components/patternLibrary/Components';

class PatternLibrary extends Component {
    render() {
        const { size } = this.props;
        return (
            <Theme>
                <PatternLibraryStyled>
                    <Colors />
                    <Components size={size} />
                </PatternLibraryStyled>
                <GlobalStyles />
            </Theme>
        );
    }
}

export default withSize()(PatternLibrary);
