import React, { Component, Fragment } from 'react';
import {
    Map,
    InfoWindow,
    Marker,
    GoogleApiWrapper,
    google,
} from 'google-maps-react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import SearchBox from './MapComponents/SearchBox';
import Autocomplete from 'react-google-autocomplete';
import Geocode from 'react-geocode';
import { getDistance, getPreciseDistance } from 'geolib';
import PinIcon from '../images/pin-icon.jpg';
import SearchIcon from '../images/search-icon.jpg';
import LocationsMapStyled from '../components/pageStyles/map-styles';

class LocationsMap extends Component {
    state = {
        activeMarker: {},
        selectedPlace: {},
        address: '',
        showingInfoWindow: false,
        mapPosition: {},
        markerPosition: {},
        markerList: [],
        markerObjects: [],
        openWindowId: '',
        zoom: 5,
        searchZoom: 8,
        pinZoom: 12,
        searchRadiusMiles: 200,
        mapApiLoaded: false,
        mapInstance: null,
        mapApi: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            address: '',
            mapPosition: {
                lat: this.props.center.lat,
                lng: this.props.center.lng,
            },
            markerPosition: {
                lat: this.props.center.lat,
                lng: this.props.center.lng,
            },
            markerList: this.props.markers,
            markerObjects: [],
            openWindowId: '',
            zoom: this.props.zoom,
            searchZoom: this.props.searchZoom,
            pinZoom: this.props.pinZoom,
            searchRadiusMiles: this.props.searchRadiusMiles,
        };
    }

    /**
     * When the user types an address in the search box
     * @param place
     */
    onPlaceSelected = (place) => {
        console.log('plc', place);
        if (
            place !== null &&
            place !== undefined &&
            place.geometry !== null &&
            place.geometry !== undefined
        ) {
            const address = place.formatted_address,
                latValue = place.geometry.location.lat(),
                lngValue = place.geometry.location.lng();
            // Set these values in the state.
            this.setState({
                address: address ? address : '',
                mapPosition: {
                    lat: latValue,
                    lng: lngValue,
                },
                zoom: this.state.searchZoom,
            });
        }
    };

    onButtonClick = () => {
        if (document.body.getElementsByClassName('pac-item').length > 0) {
            var firstResult = document.body.getElementsByClassName(
                'pac-item'
            )[0].textContent;
            var geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ address: firstResult }, this.onGeoCodeResult);
        }
    };

    onGeoCodeResult = (results, status) => {
        if (status == window.google.maps.GeocoderStatus.OK) {
            this.setState({
                mapPosition: {
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng(),
                },
                zoom: this.state.searchZoom,
            });
        }
    };

    onMarkerClick = (props, marker) => {
        this.setState({
            activeMarker: marker,
            selectedPlace: props,
            showingInfoWindow: true,
            zoom: this.props.pinZoom,
            mapPosition: props.position,
        });
    };

    onSearchResultClick = (coordinates) => {
        this.onMarkerClick(
            this.state.markerObjects[coordinates].props,
            this.state.markerObjects[coordinates].marker
        );
    };

    onInfoWindowClose = () =>
        this.setState({
            activeMarker: null,

            showingInfoWindow: false,
        });

    onMapClicked = () => {
        if (this.state.showingInfoWindow)
            this.setState({
                activeMarker: null,

                showingInfoWindow: false,
            });
    };

    apiHasLoaded = (map, maps) => {
        this.setState({
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps,
        });
    };

    render() {
        if (!this.props.loaded) return <div>Loading...</div>;

        return (
            <LocationsMapStyled>
                <Map
                    className="map"
                    google={this.props.google}
                    onClick={this.onMapClicked}
                    initialCenter={{
                        lat: this.state.mapPosition.lat,
                        lng: this.state.mapPosition.lng,
                    }}
                    center={{
                        lat: this.state.mapPosition.lat,
                        lng: this.state.mapPosition.lng,
                    }}
                    style={{ height: this.props.height }}
                    mapTypeControl={true}
                    streetViewControl={false}
                    fullscreenControl={false}
                    zoom={this.state.zoom}
                    onGoogleApiLoaded={({ map, maps }) =>
                        this.apiHasLoaded(map, maps)
                    }
                >
                    <div className="search-results-container">
                        <div className="search-box-container">
                            <div className="search-box-icon">
                                <img
                                    src={SearchIcon}
                                    width="100%"
                                    height="auto"
                                />
                            </div>
                            <Autocomplete
                                className="search-box"
                                onPlaceSelected={this.onPlaceSelected}
                                style={{ width: '90%' }}
                                onKeyPress={(ev) => {
                                    if (ev.key === 'Enter') {
                                        ev.preventDefault();
                                    }
                                }}
                                types={['(regions)']}
                                componentRestrictions={{ country: 'us' }}
                                placeholder="Search City or State"
                            />
                            <div
                                className="search-box-button"
                                onClick={this.onButtonClick}
                            >
                                SEARCH
                            </div>
                        </div>
                    </div>
                    <div className="search-result-list-container">
                        {this.state.markerList.map((element, index) => (
                            getDistance(
                                {
                                    latitude: this.state.mapPosition
                                        .lat,
                                    longitude: this.state
                                        .mapPosition.lng,
                                },
                                {
                                    latitude: element.lat,
                                    longitude: element.lng,
                                }
                            ) <
                                1610 *
                                    this.state.searchRadiusMiles &&
                            this.state.mapPosition.lat !==
                                this.props.center.lat &&
                            this.state.mapPosition.lng !==
                                this.props.center.lng 
                                &&
                            <div
                                className='search-result'
                                onClick={() =>
                                    this.onSearchResultClick(
                                        element.lat + element.lng
                                    )
                                }
                                key={index}
                            >
                                <div className="search-result-text-container">
                                    <div className="search-result-pin">
                                        <img
                                            src={PinIcon}
                                            width="12px"
                                            height="17px"
                                        />
                                    </div>
                                    <h1 className="search-result-name">
                                        {element.name}
                                    </h1>
                                    <p className="search-result-address">
                                        {element.address.includes('undefined')
                                            ? ''
                                            : element.address}
                                    </p>
                                    <p className="search-result-coordinates">
                                        {element.lat}, {element.lng}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>

                    {this.state.markerList.map((element, index) => (
                        <Marker
                            name={element.name}
                            address={element.address}
                            draggable={false}
                            position={{ lat: element.lat, lng: element.lng }}
                            onClick={this.onMarkerClick}
                            ref={(marker) =>
                                (this.state.markerObjects[
                                    element.lat + element.lng
                                ] = marker)
                            }
                            key={index}
                        ></Marker>
                    ))}

                    <InfoWindow
                        marker={this.state.activeMarker}
                        onClose={this.onInfoWindowClose}
                        visible={this.state.showingInfoWindow}
                        className="info-window"
                    >
                        {this.state.activeMarker !== undefined &&
                        this.state.activeMarker !== null ? (
                            <div className="info-window-text-container">
                                <h1 className="info-window-name">
                                    {this.state.activeMarker.name}
                                </h1>
                                <p className="info-window-address">
                                    {this.state.activeMarker.address.includes(
                                        'undefined'
                                    )
                                        ? ''
                                        : this.state.activeMarker.address}
                                </p>
                                <p className="info-window-coordinates">
                                    {this.state.activeMarker.internalPosition.lat()}
                                    ,{' '}
                                    {this.state.activeMarker.internalPosition.lng()}
                                </p>
                            </div>
                        ) : (
                            <div>
                                <h1>null</h1>
                            </div>
                        )}
                    </InfoWindow>
                </Map>
            </LocationsMapStyled>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyAqqaLaR80y12JTXFvJMJkHfWQQz8Gzirk',
})(LocationsMap);
