export const generalContentParams = () => {
    return [{
        name: 'eyebrowText',
        type: 'string',
        required: 'true',
        default: 'n/a',
        description:
            'Eyebrow text to be displayed',
    },
    {
        name: 'sectionHeader',
        type: 'string',
        required: 'false',
        default: 'n/a',
        description: 'Section header to be displayed',
    },
    {
        name: 'sectionHeaderCopy',
        type: 'string',
        required: 'false',
        default: 'n/a',
        description: 'Section header copy to be displayed',
    },
    {
        name: 'linkText',
        type: 'string',
        required: 'false',
        default: 'n/a',
        description: 'Link text to be displayed',
    },
    {
        name: 'linkUrl',
        type: 'string',
        required: 'false',
        default: 'n/a',
        description: 'URL for link',
    },
    {
        name: 'generalContentStyles',
        type: 'object',
        required: 'true',
        default: 'n/a',
        description: 'Styles object for alignment of each component',
    },
    {
        name: 'isExternal',
        type: 'bool',
        required: 'false',
        default: 'false',
        description: 'If external then Anchor will set the links',
    }
    ];
};
