import styled from 'styled-components';

const LocationsMapStyled = styled.div`
display: flex;
flex-direction: column;

input:focus, textarea:focus, select:focus{
  outline: none;
}

.map
{
  position: relative !important;
  width: 90%;
}

.info-window
{
  width: 275px;
  height: 138px;  
  overflow: hidden;
}

.info-window-text-container
{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  width: 220px;
}

.info-window-name
{
  font-family: ${(props) => props.theme.fonts[3]};
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  height: 21px;
  width: 100%;
}

.info-window-address
{
  font-family: ${(props) => props.theme.fonts[3]};
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5625rem;
  height:50px;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 6px;
}

.info-window-coordinates
{
  font-family: ${(props) => props.theme.fonts[3]};
  font-size: 1.125rem;
  line-height: 1.3125rem;
  color: ${(props) => props.theme.colors.texts.fadedGrey};   
  font-weight: 400
  height: 21px;
  width: 100%;
}

.gm-style-iw
{
  width:275px;
  height:138px;
  overflow:visible;
}

.gm-style-iw-t::after
{
  display:none;
}

.gm-style-iw-d
{
  overflow:visible !important;
  padding-top:6px;
  padding-left:9px;
  padding-right: 30px;
}

.gm-ui-hover-effect
{
  right: 12px !important;
  top: 0px !important;

  img
  {
    width: 30px !important;
    height: 30px !important;
  }
}

.search-results-container
{
  margin-top: 9px;
  position: relative;
  z-index: 5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 1023px) {
  
  .map
  {
    width: 90% !important;
    margin-left: auto;
    margin-right: auto;
  }

  .search-result
  {
    display: flex;
    min-width: 275px;
    height: 138px;  
    overflow: hidden;
    background-color: white;
    padding: 20px;
    box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);
    margin-left: 15px;
    margin-right: 15px;
    z-index: 5;
    cursor: pointer;
  }

  .search-result-list-container
  {
    display:flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: 100%;
    margin-top: 300px;
    -ms-overflow-style:none;
    z-index:5;
    height: 140px;
  }

  .search-result-list-container::-webkit-scrollbar
  {
    display:none;
  }

  .search-result-name
  {
    font-family: ${(props) => props.theme.fonts[3]};
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.3125rem;
    height: 21px;
    width: 100%;
  }

  .search-result-address
  {
    font-family: ${(props) => props.theme.fonts[3]};
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5625rem;
    height:50px;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 6px;
  }

  .search-result-coordinates
  {
    font-family: ${(props) => props.theme.fonts[3]};
    font-size: 1.125rem;
    line-height: 1.3125rem;
    color: ${(props) => props.theme.colors.texts.fadedGrey};   
    font-weight: 400
    height: 21px;
    width: 100%;
  }
}


.search-result-text-container
{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  width: 300px;
}

.search-result-pin
{
  display: none;
}

.search-box-container
{
  width: 314px;
  height: 46px;
  margin-bottom: 22px;
  margin-top: 50px;
  display: flex;
  background-color: ${(props) => props.theme.colors.white};
  @media screen and (max-width: 360px) {
       width:270px;
  }
}

.search-box-icon
{
  width: 14px;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.search-box-button
{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  background-color: ${(props) => props.theme.colors.buttons.textLink};
  color: ${(props) => props.theme.colors.texts.white};
  font-family: ${(props) => props.theme.fonts[0]};
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 0.9375rem;
  text-align: center;
  margin-left: auto;
  cursor: pointer;
}

.search-box
{
  width: 170px;
  height: 46px;
  paddingLeft: 16px;
  marginTop: 2px;
  marginBottom: 50px;

  color: ${(props) => props.theme.colors.texts.black};
  font-family: ${(props) => props.theme.fonts[3]};
  font-size: 0.9375rem;
  letter-spacing: 0;
  line-height: 1.125rem;
  border-style: none;

  ::placeholder { 
    color: ${(props) => props.theme.colors.texts.placeholderGrey};
  }
}  



@media screen and (min-width: 1024px) {
    display: flex;
    
    .scroll-menu
    {
      display:none;
    }
    
    .info-window
    {
      width: 275px;
      height: 138px;  
      overflow: hidden;
    }

    .search-results-container
    {
      margin-top: 56px;
      position: relative;
      width: 400px;
      left: 86px;
      z-index: 5;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .search-result-list-container
    {
      margin-top: 56px;
      position: absolute;
      width: 400px;
      left: 86px;
      z-index: 5;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .search-result
    {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 392px;
      height: 66px;
      padding-top: 10px;
      font-size: 0.8125rem;
      font-family: ${(props) => props.theme.fonts[3]};
      background-color: ${(props) => props.theme.colors.white};
      cursor: pointer;
    }
      
    .search-result:nth-child(n+7)
    {
      display: none !important;
    }

    .search-result-text-container
    {
      flex-direction: row;
      width: 100%;
      border-bottom: ${(props) => props.theme.borders.locationResults};
    }

    .search-result-pin
    {
      width: 60px;
      display: flex;
      justify-content: center;
      padding-bottom: 10px;
    }

    .search-result-name
    {
      line-height: 0.9375rem;
      padding-bottom: 10px;
    }

    .search-result-address
    {
      padding-left: 45px;
      padding-right: 35px;
      line-height: 0.9375rem;
      padding-bottom: 10px;
    }

    .search-result-coordinates
    {
      display: none;
    }

    .search-box-container
    {
      width: 392px;
      margin-top: 0px;
    }

    .search-box
    {
      width: 250px;
    }
  }
`;

export default LocationsMapStyled;
