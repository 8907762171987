import styled from 'styled-components';

const SubMenuStyled = styled.div`
    position: relative;

    & > nav {
        display: flex;
        flex-direction: column;
        justify-content: right;
        position: sticky;
        top: 4.3rem;
        z-index: 98;
        border-bottom: 1px solid
            ${(props) => props.theme.colors.backgrounds.altoGrey};

        @media screen and (max-width: 768px) {
            height: 71px;
            background-color: ${(props) => props.theme.colors.backgrounds.sand};
        }

        & > .subMenu__jumper {
            color: ${(props) => props.theme.colors.texts.shipGrey};
            display: block;
            position: relative;
            background-color: ${(props) => props.theme.colors.backgrounds.sand};
            padding: 16px 38px;
            font: normal 0.875rem ${(props) => props.theme.fonts[0]};
            letter-spacing: 0.165rem;
            text-transform: uppercase;

            &.close ~ div {
                display: none;
            }

            &:before {
                content: '';
                position: absolute;
                width: 25px;
                height: 25px;
                background-color: #fff;
                right: 16px;
                top: 12px;
                border-radius: 2px;
            }

            &:after {
                content: '';
                position: absolute;
                width: 10px;
                height: 10px;
                border-width: 2px;
                border-style: solid;
                border-color: transparent transparent
                    ${(props) => props.theme.colors.texts.shipGrey}
                    ${(props) => props.theme.colors.texts.shipGrey};
                transform: rotate(-45deg);
                right: 21px;
                top: 14px;
            }
        }

        .subMenu--Links > a:hover {
            color: #000;
        }

        & > .dropDownLinks {
            position: absolute;
            top: 71px;
            width: 100%;
            box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
            height: 170px;
        }

        & > div {
            text-align: center;
            background-color: #fff;
        }

        @media only screen and (width: 768px) {
            & div.subMenu__holder > div {
                flex-basis: 25%;
                padding: 0 0.95rem;
            }
        }

        @media only screen and (width: 1024px) {
            & div.subMenu__holder > div {
                flex-basis: 20vw;
            }
        }

        @media screen and (min-width: 768px) {
            flex-direction: row;
            justify-content: center;
            top: 110px;
            height: 93px;
            align-items: center;
            background-color: ${(props) => props.theme.colors.backgrounds.sand};

            & .subMenu__holder {
                display: flex;
                width: 100%;
                justify-content: center;
                background-color: ${(props) =>
                    props.theme.colors.backgrounds.sand};

                & > div {
                    padding: 0 1.25rem;
                    flex-basis: 16vw;
                    &:last-child {
                        flex-basis: 8%;
                    }
                }
            }
        }
    } /*nav ends*/

    /* target sections*/

    .subMenu--targets > section {
        display: flex;
        flex-direction: column;
        min-height: 200px;
        margin: 1.5rem;

        @media screen and (min-width: 768px) {
            margin: 1.5rem 2.5rem;
        }
    }

    #our-difference {
        margin-bottom: 130px;
    }
    .our-diff-general-content {
        margin-top: 100px;
        @media screen and (max-width: 768px) {
            margin-top: 28px;
        }
        @media screen and (max-width: 360px) {
            margin-top: 28px;

            .section-header-copy,
            .eyebrow-text,
            .section-header {
                padding-left: 17px;
                padding-right: 17px;
            }

            > :first-child {
                width: auto;
            }
        }
    }
    .mediacomponents {
        border: 1px solid #dbdbdb;
        padding: 78px 0 126px;

        @media screen and (max-width: 768px) {
            padding: 30px 0 10px;
        }

        @media screen and (min-width: 1440px) {
            width: 1260px;
            margin: auto;
        }

        @media screen and (max-width: 360px) {
            .circle-image {
                width: 250px;
                height: 250px;
                > :first-child {
                    width: 250px;
                    height: 250px;
                }
            }
            .copy-container {
                padding-left: 17px;
                padding-right: 17px;
            }
        }
    }
    .partnership-headeline {
        width: 713px;
        margin: auto;
        color: ${(props) => props.theme.colors.texts.capeCod};
        font: 1.875rem ${(props) => props.theme.fonts[0]};
        letter-spacing: 0.001875‬rem;
        line-height: 2.5rem;
        text-align: left;

        @media screen and (max-width: 768px) {
            width: 307px;
            font: 1.5rem ${(props) => props.theme.fonts[0]};
            letter-spacing: 0.00125rem;
            line-height: 2.25rem;
            margin: 34px 34px;
        }

        @media screen and (min-width: 1440px) {
            width: 745px;
            margin: auto;
            text-align: left;
        }
    }
    .partnership-container {
        padding-top: 100px;
        padding-bottom: 215px;
        background: ${(props) => props.theme.colors.backgrounds.springSky};

        .partenership-content {
            padding: 93px 0px 128px;

            @media screen and (max-width: 768px) {
                padding: 34px 0px 36px;
            }

            > article {
                margin-top: 52px;
            }

            @media screen and (max-width: 360px) {
                padding: 34px 0px 36px;

                .section-header-copy,
                .eyebrow-text,
                .section-header {
                    padding-left: 17px;
                    padding-right: 17px;
                }

                > :first-child {
                    width: auto;
                }

                h2 {
                    width: 285px;
                    margin: 34px 0 34px 17px;
                }
            }
        }

        @media screen and (max-width: 768px) {
            background: none;
            padding-bottom: 40px;
        }

        @media screen and (max-width: 360px) {
            header {
                line-height: 2.062rem;
                margin-top: 10px;
            }
        }
    }

    .our-management-team-header,
    .frequently-asked-ques-header {
        color: ${(props) => props.theme.colors.texts.capeCod};
        font: 1.875rem ${(props) => props.theme.fonts[0]};
        letter-spacing: 0.001875rem;
        line-height: 2.5rem;
        margin-top: 100px;

        :after {
            content: '';
            display: block;
            border-bottom: 2px solid #bebebe;
            margin-top: 30px;
        }

        @media screen and (max-width: 768px) {
            margin-top: 34px;
        }
    }
    #leadership,
    #faqs {
        @media screen and (min-width: 1440px) {
            max-width: 1260px;
            margin: auto;
        }
    }

    #faqs:last-child {
        margin-bottom: 69px;
    }

    @media screen and (max-width: 360px) {
        #faqs article {
            padding: 26px 16px;
        }

        #leadership article {
            padding: 26px 16px;
        }
    }
`;

export default SubMenuStyled;
