export const listItemParams = () => {
    return [
        {
            name: 'itemLists',
            type: 'obj',
            required: 'true',
            default: 'n/a',
            description: 'Used on About us in green bullet lists',
        },
        {
            name: 'window',
            type: 'obj',
            required: 'false',
            default: 'n/a',
            description: 'Detect window size for resolution specific logic',
        },
    ];
};