export const buttonParams = () => {
    return [
        {
            name: 'className',
            type: 'string',
            required: 'false',
            default: 'n/a',
            description: 'Developer defined class name for styling',
        },
        {
            name: 'clicked',
            type: 'func',
            required: 'false',
            default: 'n/a',
            description:
                'Function to execute when button is clicked if not a link',
        },
        {
            name: 'isExternal',
            type: 'bool',
            required: 'false',
            default: 'false',
            description:
                'Does it link to an external resource? If yes set as <a> if no set as <Link>',
        },
        {
            name: 'isLink',
            type: 'bool',
            required: 'false',
            default: 'true',
            description:
                'Does this link to another page? If yes set as <Link> or <a> if no set as <button>',
        },
        {
            name: 'isPrimary',
            type: 'bool',
            required: 'false',
            default: 'true',
            description:
                'If true use primary button props, if false use secondary button props',
        },
        {
            name: 'linkURL',
            type: 'string',
            required: 'false',
            default: 'n/a',
            description: 'Link to target page/resource',
        },
        {
            name: 'sidePadding',
            type: 'string',
            required: 'false',
            default: '32px',
            description: 'Amount of padding to the left and right of the text',
        },
        {
            name: 'text',
            type: 'string',
            required: 'true',
            default: 'n/a',
            description:
                'Does this link to another page? If yes set as <Link> or <a> if no set as <button>',
        },
        {
            name: 'verticalPadding',
            type: 'string',
            required: 'false',
            default: '16px',
            description: 'Amount of padding to the top and bottom of the text',
        },
    ];
};
