import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BodyCopyStyled = styled.p`
    color: ${(props) => props.theme.colors.texts.shipGrey};
    font: 1.25rem ${(props) => props.theme.fonts[0]};
    letter-spacing: 0;
    line-height: 42px;
    @media screen and (max-width: 768px) {
        font: 1.125rem ${(props) => props.theme.fonts[0]};
        letter-spacing: 0;
        line-height: 38px;
        width:279px;
    }   
    
`
export default function BodyCopy({
    bodyCopyText
}) {
    return (
        <BodyCopyStyled >
            {bodyCopyText}
        </BodyCopyStyled>
    )
}
BodyCopy.propTypes = {
    bodyCopyText: PropTypes.string.isRequired
};

