import React from 'react';
import styled from 'styled-components';

import Accordion from '../Accordion';
import Button from '../ui/Controls/Button';
import CircleImage from '../ui/CircleImage';
import ComponentContainer from './ComponentContainer';
import ContactCard from '../ContactCard';
import GeneralContent from '../GeneralContent';
import HeadlineBox from '../HeadlineBox';
import ImageWithCopy from '../ImageWithCopy';
import InfoCard from '../InfoCard';
import LocationsMap from '../LocationsMap';
import LargeList from '../LargeList';
import Logo from '../ui/SVGs/Logo';
import ListItem from '../ListItem';
import MiniCard from '../MiniCard';
import PullQuoteBox from '../PullQuoteBox';
import SubMenu from '../SubMenu/SubMenu';

import AnujSharma from '../../images/anuj.png';
import FamilyBusiness from '../../images/family-business.png';
import MoreThanBusiness from '../../images/more-than-a-business.png';
import WorkWithUs from '../../images/work-with-us.png';
import PWSFacility from '../../images/pws-facility.png';
import * as allParams from './ComponentParams/params';

const ComponentsStyled = styled.section`
    .component-group {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-top: 50px;
        margin-bottom: 20px;

        @media screen and (min-width: 768px) {
            flex-direction: row;
            width: 90%;
            max-width: 1420px;
        }
    }
`;

export default function Components({ size }) {
    const infoCardImgObj = {
        imgAlt:
            'Image of two workers standing around oil field, wearing hard hats and reflective vests',
        imgSrc: WorkWithUs,
        imgTitle: 'Work with us',
    };
    const bioImgObj = {
        imgAlt: 'Headshot of PWS CEO Anuj Sharma',
        imgSrc: AnujSharma,
        imgTitle: 'PWS CEO Anuj Shaarm',
    };

    const itemLists = [
        {
            title: 'No Capital Constraints',
            text: 'PWS has the ability to provide funding',
        },
        {
            title: 'Long Term Fixed Rate',
            text: 'Allows for reliable and cost effective take away',
        },
        {
            title: 'Safety and Operational Standards',
            text: 'PWS puts safety and the environment first',
        },
        {
            title: 'System Build Out',
            text:
                'With no capital constraints, PWS is prepared to build and bolt on to the existing system allowing for additional gathering opportunities',
        },
    ];

    return (
        <ComponentsStyled>
            <span className="section__title">Components</span>
            <section>
                <ComponentContainer
                    componentName="Accordion"
                    description="Used for displaying minimal information with button to click and display more information"
                >
                    <Accordion
                        copy="Mr. Sharma joined PWS in October 2019 and has 20 years’ experience in the oil and gas industry. Prior to Pilot, Mr. Sharma was the CEO of Phoenix Global Resources, plc, a London listed Independent E&amp;P Company focused on unconventional development in Argentina. Prior to this, Mr. Sharma headed Mercuria Energy Group’s investments in Argentina where he led Mercuria’s upstream oil and gas company, PETSA, to significant exploration and development success and took the company public. Prior to Mercuria, he was the Vice President and Director, originating investment opportunities for a multi-billion dollar family office in Houston, USA. Mr. Sharma also held positions in upstream equity research in Investment Banking, and portfolio management for Constellation Energy Commodities Group, making principal investments in the upstream oil and gas sector and managing the firm’s unconventional shale portfolio in the US. He started his oil and gas career as an engineer for Schlumberger Oil Field Services. Mr. Sharma received his Bachelor of Electrical Engineering from MNIT in India and an MBA from Duke University, USA where he graduated with the highest honors as Fuqua Scholar."
                        imgObj={bioImgObj}
                        subTitle="CEO"
                        title="Anuj Sharma"
                    />
                    <span className="spacer"></span>
                    <Accordion
                        copy="Mr. Sharma joined PWS in October 2019 and has 20 years’ experience in the oil and gas industry. Prior to Pilot, Mr. Sharma was the CEO of Phoenix Global Resources, plc, a London listed Independent E&amp;P Company focused on unconventional development in Argentina. Prior to this, Mr. Sharma headed Mercuria Energy Group’s investments in Argentina where he led Mercuria’s upstream oil and gas company, PETSA, to significant exploration and development success and took the company public. Prior to Mercuria, he was the Vice President and Director, originating investment opportunities for a multi-billion dollar family office in Houston, USA. Mr. Sharma also held positions in upstream equity research in Investment Banking, and portfolio management for Constellation Energy Commodities Group, making principal investments in the upstream oil and gas sector and managing the firm’s unconventional shale portfolio in the US. He started his oil and gas career as an engineer for Schlumberger Oil Field Services. Mr. Sharma received his Bachelor of Electrical Engineering from MNIT in India and an MBA from Duke University, USA where he graduated with the highest honors as Fuqua Scholar."
                        subTitle="CEO"
                        title="Anuj Sharma"
                    />
                    <span className="spacer"></span>
                    <Accordion
                        copy="The safety of our team members and customers is our #1 priority, and team members are instructed to shut down operations whenever they feel unsafe or spot a safety risk."
                        isPrimaryType={false}
                        title="What is Pilot Water Solutions’ attitude towards safety?"
                    />
                </ComponentContainer>
                <ComponentContainer
                    componentName="Button"
                    description="Used for general user interaction. Found in header as the contact button."
                    params={allParams.buttonParams()}
                >
                    <Button text="Primary Button" isLink={false} />
                    <span className="spacer"></span>
                    <Button
                        text="Secondary Button"
                        isLink={false}
                        isPrimary={false}
                    />
                </ComponentContainer>
                <ComponentContainer
                    componentName="CircleImage"
                    description="Used to bring emphasis to text"
                    params={allParams.circleImageParams()}
                >
                    <section className="component-group">
                        <CircleImage
                            imgAlt="Circle Component Demo"
                            imgSrc={FamilyBusiness}
                            imgTitle="Circle Component Demo"
                            windowSize={size}
                        />
                        <span className="spacer"></span>
                        <CircleImage
                            imgAlt="Circle Component Demo"
                            imgSrc={FamilyBusiness}
                            imgTitle="Circle Component Demo"
                            title="Water Sourcing &amp; Disposal"
                            windowSize={size}
                        />
                        <span className="spacer"></span>
                        <CircleImage
                            imgAlt="Circle Component Demo"
                            imgSrc={FamilyBusiness}
                            imgTitle="Circle Component Demo"
                            isPrimary={false}
                            windowSize={size}
                        />
                    </section>
                </ComponentContainer>
                <ComponentContainer
                    componentName="ContactCard"
                    description="Contact Card with link"
                >
                    <ContactCard />
                </ComponentContainer>
                <ComponentContainer
                    componentName="GeneralContent"
                    description="Used in most of the scaffolding pages"
                    params={allParams.generalContentParams()}
                >
                    <GeneralContent
                        eyebrowText="Our Services"
                        sectionHeader="A partner for the energy industry"
                        sectionHeaderCopy="One of the largest full-service water solutions providers in the energy industry. We work with large and small operators on all oil field water logistics and disposal needs. PWS is uniquely positioned to provided tailored solutions for any water midstream challenge."
                        linkText="See our locations"
                        linkUrl="/locations"
                        generalContentStyles={{
                            textAlign: 'center',
                            eyebrow: {
                                textAlign: 'center',
                            },
                            header: {
                                textAlign: 'center',
                                mobile: {
                                    textAlign: 'center',
                                },
                            },
                            copy: {
                                textAlign: 'center',
                                mobile: {
                                    textAlign: 'center',
                                },
                            },
                        }}
                        isExternal={false}
                    />
                </ComponentContainer>
                <ComponentContainer
                    componentName="HeadlineBox"
                    description="Used as text box in Hero"
                    params={allParams.headlineBoxParams()}
                >
                    <HeadlineBox text="This is a HeadlineBox component for demo purposes." />
                </ComponentContainer>
                <ComponentContainer
                    componentName="ImageWithCopy"
                    description="Creates an image with a copy and link, can be left or right aligned and square or circle shaped"
                    params={allParams.logoParams()}
                >
                    <ImageWithCopy
                        size={size}
                        imgSrc={FamilyBusiness}
                        imageLeft={false}
                        squareImage={true}
                        showDotGrid={true}
                        copyTitle="More Than a Business"
                        copyText="Wherever oil is produced, there is a need to dispose of its byproducts in a safe, responsible way. For our team members, oilfield services isn’t just a business—it’s a legacy that serves the community."
                        copyLinkText="Learn More"
                        linkTarget="/about"
                    />
                    <ImageWithCopy
                        size={size}
                        imageLeft={true}
                        squareImage={true}
                        imgSrc={FamilyBusiness}
                        copyTitle="More Than a Business"
                        copyText="Wherever oil is produced, there is a need to dispose of its byproducts in a safe, responsible way. For our team members, oilfield services isn’t just a business—it’s a legacy that serves the community."
                        copyLinkText="Learn More"
                        linkTarget="/about"
                    />
                    <ImageWithCopy
                        size={size}
                        imageLeft={false}
                        squareImage={false}
                        imgSrc={MoreThanBusiness}
                        copyTitle="More Than a Business"
                        copyText="Wherever oil is produced, there is a need to dispose of its byproducts in a safe, responsible way. For our team members, oilfield services isn’t just a business—it’s a legacy that serves the community."
                        copyLinkText="Learn More"
                        linkTarget="/about"
                    />
                </ComponentContainer>
                <ComponentContainer
                    componentName="InfoCard"
                    description="Can contain an image to the right or left and/or call to action and displays title and copy"
                    params={allParams.infoCardParams()}
                >
                    <InfoCard
                        copy="Pilot Water Solutions is currently seeking drivers and production/process engineers."
                        ctaLink="/contact"
                        ctaText="Contact Us"
                        imgObj={infoCardImgObj}
                        title="Work with us"
                        windowSize={size}
                    />
                    <span className="spacer"></span>
                    <InfoCard
                        copy="Pilot Water Solutions is currently seeking drivers and production/process engineers."
                        ctaLink="/contact"
                        ctaText="Contact Us"
                        title="Work with us"
                        windowSize={size}
                    />
                </ComponentContainer>
                <ComponentContainer
                    componentName="Interior Sub Menu "
                    description="Used in About us page for navigating various sections"
                    params={allParams.subMenuParams()}
                >
                    <SubMenu
                        getWindowSize={size}
                        subMenuLinks={[
                            'Our Difference',
                            'Partnership',
                            'Leadership',
                            'FAQs',
                        ]}
                    />
                </ComponentContainer>
                <ComponentContainer
                    componentName="LargeList"
                    description="used in services"
                    params={allParams.largeListParams()}
                >
                    <LargeList
                        size={size}
                        imgSrc={FamilyBusiness}
                        imgAlt="Circle image"
                        imgTitle="Circle Image"
                        isPrimary={true}
                        headlineText="Water Sourcing & Recycling"
                        bodyCopyText="We employ geologists and engineers that specialize in water sourcing and recycling. We have in depth basin specific knowledge and producer back experience that allows us to deliver and perform to the standards, specifications and KPIs demanded by every operator."
                    />
                </ComponentContainer>
                <ComponentContainer
                    componentName="ListItem"
                    description="Used in About us with green solid bullets"
                    params={allParams.listItemParams()}
                >
                    <ListItem itemLists={itemLists} />
                </ComponentContainer>
                <ComponentContainer
                    componentName="Logo"
                    description="PWS Logo can be used anywhere a logo is needed. Just pass height and width if needed. Can be blue or white."
                    params={allParams.logoParams()}
                >
                    <Logo />
                    <span className="spacer"></span>
                    <Logo isLogoWhite={true} />
                </ComponentContainer>
                <ComponentContainer
                    componentName="MiniCard"
                    description="Used to display stats with an image."
                    params={allParams.miniCardParams()}
                >
                    <MiniCard
                        bgImage={PWSFacility}
                        stat="50+"
                        title="Commercial Salt Water Disposal Wells"
                    />
                </ComponentContainer>
                <ComponentContainer
                    componentName="PullQuoteBox"
                    description="Typically used in the header of a page to display important information."
                    params={allParams.pullQuoteBoxParams()}
                >
                    <PullQuoteBox
                        Style="max-width:500px"
                        quoteTitle="Produced Water Management Services"
                        quoteContent="Pilot Water Solutions is a full-service water midstream company with the capability to provide cradle to grave water solutions. Our expertise includes:"
                    />
                </ComponentContainer>
                <ComponentContainer
                    componentName="GoogleMap"
                    description="Displays locations on a google map"
                >
                    <LocationsMap
                        center={{ lat: 39.5, lng: -98.35 }}
                        markers={[
                            {
                                lat: 39.5,
                                lng: -98.35,
                                name: 'PWS Location 1',
                                address: '123 fake street, fake town MA',
                            },
                            {
                                lat: 41.5,
                                lng: -96.35,
                                name: 'PWS Location 2',
                                address: '234 fake street, fake town MA',
                            },
                            {
                                lat: 37.5,
                                lng: -100.35,
                                name: 'PWS Location 3',
                                address: '456 fake street, fake town MA',
                            },
                        ]}
                        height="600px"
                        zoom={5}
                        searchZoom={6}
                        pinZoom={12}
                        searchRadiusMiles={200}
                    />
                </ComponentContainer>
            </section>
        </ComponentsStyled>
    );
}
