import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Anchor from '../ui/Controls/Anchor';
import scrollToElement from 'scroll-to-element';

import GeneralContent from '../GeneralContent';
import ImageWithCopy from '../ImageWithCopy';
import ListItem from '../ListItem';
import InfoCard from '../InfoCard';
import Accordion from '../Accordion';

import SubMenuStyled from './SubMenu-Styles';

export default function SubMenu(props) {
    let { className, subMenuLinks, getWindowSize, subMenuContent } = props;

    const [isMobile, setIsMobile] = useState(false);
    const [isMobileSubMenuOpen, setIsMobileSubMenuOpen] = useState(false);
    const mobileNavToggle = (_) => setIsMobileSubMenuOpen(!isMobileSubMenuOpen);

    useEffect(() => {
        props.getWindowSize.width >= 768
            ? window.addEventListener('scroll', handleScroll)
            : window.removeEventListener('scroll', handleScroll);
    }, [props.getWindowSize]);

    const scrollToElementHandler = (event, target) => {
        event.preventDefault();
        let element = '';
        element = document.getElementById(subMenuIDGenerator(target));

        if (window.outerWidth < 768) {
            const getJumper = document.querySelector('.subMenu__jumper');
            getJumper.innerHTML = target;
            scrollToElement(element, {
                offset: -140,
                duration: 1000,
            });
        } else {
            scrollToElement(element, {
                offset: -200,
                duration: 1000,
            });
        }
    };

    const handleScroll = (event) => {
        let sections = {};
        let i = 0;
        const section = document.querySelectorAll('.section');
        Array.prototype.forEach.call(section, function (e) {
            sections[e.id] = e.getBoundingClientRect().top;
        });

        const subMenuTopOffset = document.querySelector(
            '.scrollSpyWrapper '
        );

        for (i in sections) {
            const subMenuActiveLink = document.querySelector(
                '.subMenu--Links.active'
            );
            if (
                sections[i] -
                    (subMenuTopOffset.getBoundingClientRect().top +
                        (subMenuTopOffset.getBoundingClientRect().height +
                            50)) <=
                0
            ) {
                subMenuActiveLink &&
                    subMenuActiveLink.classList.remove('active');
                document.querySelector('.' + i).classList.add('active');
            }
        }
    };

    const subMenuIDGenerator = (text) => {
        let txt = text.toLowerCase().replace(/\s/g, '-');
        return txt;
    };
    const nav =
        subMenuLinks &&
        subMenuLinks.map((item, index) => {
            return (
                <Anchor
                    linkURL={''}
                    key={index}
                    text={item}
                    className={`subMenu--Links ${subMenuIDGenerator(item)} ${
                        index === 0 ? 'active' : ''
                    }`}
                    clickEvent={(e) => {
                        scrollToElementHandler(e, item);
                        mobileNavToggle();
                    }}
                ></Anchor>
            );
        });

    const showSubmenuSection = subMenuLinks.map((item, index) => {
        switch (item) {
            case 'Our Difference':
                return (
                    <section
                        className="section"
                        id={subMenuIDGenerator(item)}
                        key={index}
                    >
                        <div className="our-diff-general-content">
                            <GeneralContent
                                eyebrowText={
                                    subMenuContent &&
                                    subMenuContent.ourDifferenceTextBlock[0]
                                        .eyebrow
                                }
                                sectionHeader=""
                                sectionHeaderCopy={
                                    subMenuContent &&
                                    subMenuContent.ourDifferenceTextBlock[0]
                                        .copy
                                }
                                linkText=""
                                linkUrl=""
                                generalContentStyles={{
                                    textAlign: 'center',
                                    eyebrow: {
                                        textAlign: `${
                                            subMenuContent &&
                                            subMenuContent
                                                .ourDifferenceTextBlock[0]
                                                .eyebrowAlignment
                                        }`,
                                    },
                                    header: {
                                        textAlign: 'center',
                                        mobile: {
                                            textAlign: 'center',
                                        },
                                    },
                                    copy: {
                                        textAlign: `${
                                            subMenuContent &&
                                            subMenuContent
                                                .ourDifferenceTextBlock[0]
                                                .copyAlignment
                                        }`,
                                        mobile: {
                                            textAlign: `${
                                                subMenuContent &&
                                                subMenuContent
                                                    .ourDifferenceTextBlock[0]
                                                    .copyAlignment
                                            }`,
                                        },
                                    },
                                }}
                                isExternal={
                                    subMenuContent &&
                                    subMenuContent.ourDifferenceTextBlock[0]
                                        .ctaIsExternal
                                }
                            ></GeneralContent>
                        </div>
                        <div className="mediacomponents">
                            {subMenuContent &&
                                subMenuContent.mediaComponents.map(
                                    (content, index) => {
                                        return (
                                            <ImageWithCopy
                                                size={getWindowSize}
                                                imageLeft={
                                                    content.imageAlignment ===
                                                    'Left'
                                                        ? true
                                                        : false
                                                }
                                                squareImage={false}
                                                copyTitle={content.title}
                                                copyText={content.text}
                                                copyLinkText={content.ctaText}
                                                linkTarget={content.ctaUrl}
                                                imgSrc={content.image[0]}
                                                key={index}
                                            />
                                        );
                                    }
                                )}
                        </div>
                    </section>
                );
            case 'Partnership':
                return (
                    <div className="partnership-container" key={index}>
                        <section
                            className="section"
                            id={subMenuIDGenerator(item)}
                        >
                            <div className="partnership-content">
                                <GeneralContent
                                    eyebrowText={
                                        subMenuContent &&
                                        subMenuContent.partnershipTextBlock[0]
                                            .eyebrow
                                    }
                                    sectionHeader=""
                                    sectionHeaderCopy={
                                        subMenuContent &&
                                        subMenuContent.partnershipTextBlock[0]
                                            .copy
                                    }
                                    linkText=""
                                    linkUrl=""
                                    generalContentStyles={{
                                        textAlign: 'center',
                                        eyebrow: {
                                            textAlign: `${
                                                subMenuContent &&
                                                subMenuContent
                                                    .partnershipTextBlock[0]
                                                    .eyebrowAlignment
                                            }`,
                                        },
                                        header: {
                                            textAlign: 'center',
                                            mobile: {
                                                textAlign: 'center',
                                            },
                                        },
                                        copy: {
                                            textAlign: `${
                                                subMenuContent &&
                                                subMenuContent
                                                    .partnershipTextBlock[0]
                                                    .copyAlignment
                                            }`,
                                            mobile: {
                                                textAlign: `${
                                                    subMenuContent &&
                                                    subMenuContent
                                                        .partnershipTextBlock[0]
                                                        .copyAlignment
                                                }`,
                                            },
                                        },
                                    }}
                                    isExternal={
                                        subMenuContent &&
                                        subMenuContent.partnershipTextBlock[0]
                                            .ctaIsExternal
                                    }
                                ></GeneralContent>
                                <h2 className="partnership-headeline">
                                    {subMenuContent &&
                                        subMenuContent.partnershipHeadline}
                                </h2>
                                <ListItem
                                    itemLists={
                                        subMenuContent &&
                                        subMenuContent.benefits
                                    }
                                />
                                <InfoCard
                                    copy={
                                        subMenuContent &&
                                        subMenuContent.infoCard[0].text
                                    }
                                    ctaLink={
                                        subMenuContent &&
                                        subMenuContent.infoCard[0].ctaUrl
                                    }
                                    ctaText={
                                        subMenuContent &&
                                        subMenuContent.infoCard[0].ctaText
                                    }
                                    imgObj={{
                                        imgAlt: 'Workers on the job laughing',
                                        imgSrc:
                                            subMenuContent &&
                                            subMenuContent.infoCard[0].image[0],
                                        imgTitle: 'Workers on the job laughing',
                                    }}
                                    title={
                                        subMenuContent &&
                                        subMenuContent.infoCard[0].eyebrow
                                    }
                                    windowSize={getWindowSize}
                                />
                            </div>
                        </section>
                    </div>
                );
            case 'Leadership':
                return (
                    <section
                        className="section"
                        id={subMenuIDGenerator(item)}
                        key={index}
                    >
                        <h2 className="our-management-team-header">
                            Our Management Team
                        </h2>
                        {subMenuContent &&
                            subMenuContent.bios.map((bio, index) => {
                                return (
                                    <Accordion
                                        copy={bio.bioText}
                                        subTitle={bio.title}
                                        title={bio.name}
                                        key={index}
                                    />
                                );
                            })}
                    </section>
                );
            case 'FAQs':
                return (
                    <section
                        className="section"
                        id={subMenuIDGenerator(item)}
                        key={index}
                    >
                        <h2 className="frequently-asked-ques-header">
                            Frequently Asked Questions
                        </h2>
                        {subMenuContent &&
                            subMenuContent.faqs.map((faq, index) => {
                                return (
                                    <Accordion
                                        copy={faq.answer}
                                        isPrimaryType={false}
                                        title={faq.question}
                                        key={index}
                                    />
                                );
                            })}
                    </section>
                );
            default:
                return '';
        }
    });

    return (
        <SubMenuStyled className={`scrollSpyWrapper ${className}`}>
            <nav
                className={`${
                    getWindowSize && getWindowSize.width < 768
                        ? 'subMenuMobile'
                        : ''
                }`}
            >
                {getWindowSize && getWindowSize.width < 768 ? (
                    <label
                        onClick={() => mobileNavToggle()}
                        className={`subMenu__jumper ${
                            isMobileSubMenuOpen ? 'open' : 'close'
                        }`}
                    >
                        Jump to section
                    </label>
                ) : (
                    ''
                )}
                <div
                    className={`subMenu__holder ${
                        getWindowSize && getWindowSize.width < 768
                            ? 'dropDownLinks'
                            : ''
                    }`}
                >
                    {nav}
                </div>
            </nav>
            <div className={`subMenu--targets `}>{showSubmenuSection}</div>
        </SubMenuStyled>
    );
}

SubMenu.propTypes = {
    subMenuLinks: PropTypes.array.isRequired,
    className: PropTypes.string,
    getWindowSize: PropTypes.object,
};

SubMenu.defaultProps = {
    className: '',
};
