import styled from 'styled-components';

const PatternLibraryStyled = styled.div`
    background-color: #e8e8e8;
    padding: 40px 10px;
    font-family: ${(props) => props.theme.fonts[0]}, sans-serif;
    /*overflow: scroll;*/

    @media screen and (min-width: 1024px) {
        padding: 40px;
    }

    .section__title {
        font: 700 2rem ${(props) => props.theme.fonts[0]}, sans-serif;
        color: #292929;
    }

    .spacer {
        margin-right: 16px;
    }
`;

export default PatternLibraryStyled;
