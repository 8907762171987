export const circleImageParams = () => {
    return [
        {
            name: 'imgAlt',
            type: 'string',
            required: 'true',
            default: 'n/a',
            description: 'Text to populate the alt attribute of the img tag',
        },
        {
            name: 'imgSrc',
            type: 'string',
            required: 'true',
            default: 'n/a',
            description: 'Directory location of the needed image',
        },
        {
            name: 'imgTitle',
            type: 'string',
            required: 'true',
            default: 'n/a',
            description: 'Text to populate the title attribute of the img tag',
        },
        {
            name: 'isPrimary',
            type: 'bool',
            required: 'false',
            default: 'true',
            description: 'Toggles if CircleImage has border or dot grid',
        },
        {
            name: 'title',
            type: 'string',
            required: 'false',
            default: 'n/a',
            description:
                'Text to populate the title that displays under the image',
        },
        {
            name: 'windowSize',
            type: 'obj',
            required: 'true',
            default: 'n/a',
            description:
                'Object that gives width and height of the current browser state',
        },
    ];
};
