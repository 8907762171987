import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from '../ui/Controls/Button';

const CopyToClipboardStyled = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    form {
        width: 400px;
        height: 47px;
        margin-right: 4px;
        background-color: lightgray;

        input {
            width: 100%;
            padding-left: 8px;
            font-family: monospace, monospace;
            font-size: 12px;
            background-color: lightgray;
            border: none;
            box-sizing: border-box;
        }
    }

    p {
        margin: 0 0 0 4px;
        font: 16px ${(props) => props.theme.fonts[0]}, sans-serif;
        text-transform: uppercase;
    }
`;

export default function CopyToClipboard({ stringToCopy }) {
    const [copySuccess, setCopySuccess] = useState('');
    const inputRef = useRef(null);

    function copyToClipboard(e) {
        inputRef.current.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the the whole text area selected.
        e.target.focus();
        setCopySuccess('Copied!');
    }

    return (
        <CopyToClipboardStyled>
            <form>
                <input ref={inputRef} value={stringToCopy} readOnly />
            </form>
            {document.queryCommandSupported('copy') && (
                <>
                    <Button
                        isLink={false}
                        clicked={copyToClipboard}
                        text="Copy"
                    ></Button>
                    <p>{copySuccess}</p>
                </>
            )}
        </CopyToClipboardStyled>
    );
}

CopyToClipboard.propTypes = {
    stringToCopy: PropTypes.string.isRequired,
};
