import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from './ui/Controls/Button';

const InfoCardStyled = styled.article`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => (props.hasImage ? 'flex-start' : 'center')};
    width: 87%;
    margin: auto;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    @media screen and (min-width: 768px) {
        flex-direction: row;
        align-items: center;
        justify-content: ${(props) =>
            props.hasImage ? 'space-between' : 'center'};
        max-width: 1260px;
    }

    img {
        width: 100%;
        height: auto;
    }

    figure {
        @media screen and (min-width: 768px) {
            background: ${(props) =>
                `url(${props.imgSrc}) no-repeat center center`};
            background-position: center;
            background-size: cover;
            width: 49%;
            height: 422px;
        }
    }

    .infoCard__content {
        display: flex;
        flex-direction: column;
        align-items: space-around;
        justify-content: ${(props) =>
            props.hasImage ? 'flex-start' : 'center'};
        width: 100%;
        padding: ${(props) => (props.hasImage ? '26px 18px' : '80px 18px')};

        @media screen and (min-width: 768px) {
            justify-content: center;
            width: 49%;
            min-height: 422px;
            padding: ${(props) => (props.hasImage ? '26px 4%' : '0')};
        }

        p {
            display: inline;
        }

        .infoCard__title {
            font: 700 1rem ${(props) => props.theme.fonts[0]}, sans-serif;
            letter-spacing: 1.92px;
            line-height: 1.875rem;
            text-align: ${(props) => (props.hasImage ? 'left' : 'center')};
            text-transform: uppercase;

            &::after {
                display: block;
                content: ' ';
                width: 90px;
                height: 4px;
                margin: ${(props) =>
                    props.hasImage ? '10px 0 0 0' : '10px auto 0'};
                background-color: ${(props) => props.theme.colors.secondary};
            }
        }

        .infoCard__copy {
            margin: 27px 0 31px;
            font: 1.125rem ${(props) => props.theme.fonts[1]};
            line-height: 2.375rem;
            text-align: ${(props) => (props.hasImage ? 'left' : 'center')};
        }

        .infoCard__cta {
            align-self: ${(props) =>
                props.hasImage ? 'flex-start' : 'center'};
            margin-top: ${(props) => (props.hasImage ? '0' : '20px')};

            @media screen and (min-width: 768px) {
                margin-top: 0px;
            }
        }
    }
`;

export default function InfoCard({
    copy,
    ctaClicked,
    ctaLink,
    ctaText,
    imgObj,
    isCTAExternal,
    isCTALink,
    title,
    windowSize,
}) {
    return (
        <InfoCardStyled
            hasImage={imgObj ? true : false}
            imgSrc={imgObj ? imgObj.imgSrc : undefined}
        >
            {imgObj ? (
                windowSize.width < 768 ? (
                    <img
                        src={imgObj.imgSrc}
                        width="619"
                        height="422"
                        alt={imgObj.imgAlt}
                        title={imgObj.imgTitle}
                    />
                ) : (
                    <figure></figure>
                )
            ) : null}
            <section className="infoCard__content">
                <p className="infoCard__title">{title}</p>
                <p className="infoCard__copy">{copy}</p>
                {ctaLink ? (
                    <Button
                        className="infoCard__cta"
                        clicked={ctaClicked ? ctaClicked : undefined}
                        isExternal={isCTAExternal}
                        isLink={isCTALink}
                        linkURL={ctaLink}
                        text={ctaText}
                    />
                ) : null}
            </section>
        </InfoCardStyled>
    );
}

InfoCard.propTypes = {
    ctaClicked: PropTypes.func,
    copy: PropTypes.string.isRequired,
    ctaLink: PropTypes.string,
    ctaText: PropTypes.string,
    imgObj: PropTypes.shape({
        imgAlt: PropTypes.string.isRequired,
        imgSrc: PropTypes.string.isRequired,
        imgTitle: PropTypes.string.isRequired,
    }),
    isCTAExternal: PropTypes.bool,
    isCTALink: PropTypes.bool,
    title: PropTypes.string.isRequired,
    windowSize: PropTypes.object.isRequired,
};

InfoCard.defaultProps = {
    isCTAExternal: false,
    isCTALink: true,
};
