import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const OpenAccordionStyled = styled.svg`
    &:hover {
        cursor: pointer;

        circle {
            fill: ${(props) => props.theme.colors.buttons.primary};
            stroke: none;
            transition: all 0.25s;
        }

        polygon {
            fill: #fff;
            transition: all 0.25s;
        }
    }
`;

export default function OpenAccordion({ clicked, mouseEnter, mouseLeave }) {
    return (
        <OpenAccordionStyled
            className="open-accordion"
            xmlns="http://www.w3.org/2000/svg"
            width="53"
            height="53"
            viewBox="0 0 53 53"
            onClick={clicked}
            onMouseEnter={() => mouseEnter()}
            onMouseLeave={() => mouseLeave()}
        >
            <g fill="none" fillRule="evenodd">
                <circle
                    cx="26.5"
                    cy="26.5"
                    r="26"
                    fill="#FFF"
                    stroke="#DBDBDB"
                />
                <polygon
                    fill="#404041"
                    points="34.9 27.278 34.9 26 18 26 18 27.278"
                />
                <polygon
                    fill="#404041"
                    points="34.9 27.278 34.9 26 18 26 18 27.278"
                    transform="rotate(-90 26.45 26.639)"
                />
            </g>
        </OpenAccordionStyled>
    );
}

OpenAccordion.propTypes = {
    clicked: PropTypes.func.isRequired,
    mouseEnter: PropTypes.func,
    mouseLeave: PropTypes.func,
};
