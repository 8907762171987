import React from 'react';
import styled from 'styled-components';
import ColorSwatch from './ColorSwatch';

const ColorsStyled = styled.section`
    section {
        display: flex;
        flex-wrap: wrap;
    }
`;

export default function Colors() {
    return (
        <ColorsStyled>
            <span className="section__title">Button and Link Colors</span>
            <section>
                <ColorSwatch
                    colorCode="props => props.theme.colors.buttons.primary"
                    colorName="Sea Green"
                    hexColor="#2E7764"
                    hint="Green Accents, Main Button, Sm Headers, active nav link underline."
                />
                <ColorSwatch
                    colorCode="props => props.theme.colors.buttons.primaryHover"
                    colorName="Deep Sea Green"
                    hexColor="#085843"
                    hint="Hover for Green Accents, Main Button, Sm Headers"
                />
                <ColorSwatch
                    colorCode="props => props.theme.colors.buttons.secondary"
                    colorName="PWS Blue"
                    hexColor="#1362A2"
                    hint="Buttons, H1 Headers, Nav Link on-state Underline"
                />
                <ColorSwatch
                    colorCode="props => props.theme.colors.buttons.secondaryHover"
                    colorName="Venice - Hover"
                    hexColor="#084779"
                    hint="Hover for Buttons, Text Buttons, In-line Copy
                                Links"
                />
                <ColorSwatch
                    colorCode="props => props.theme.colors.buttons.disabled"
                    colorName="Disabled"
                    hexColor="#9A9FA8"
                    hint="Disabled Button"
                />
                <ColorSwatch
                    colorCode="props => props.theme.colors.buttons.textLink"
                    colorName="Mariner"
                    hexColor="#266FC5"
                    hint="Text Buttons, In-line Copy Links"
                />
            </section>
            <span className="section__title">Text Colors</span>
            <section>
                <ColorSwatch
                    colorCode="props => props.theme.colors.texts.shipGrey"
                    colorName="Ship Grey"
                    hexColor="#404041"
                    hint="Body Text, Nav Link"
                />
                <ColorSwatch
                    colorCode="props => props.theme.colors.texts.capeCod"
                    colorName="Cape Cod"
                    hexColor="#323333"
                    hint="Headings, Pull Quote"
                />
                <ColorSwatch
                    colorCode="props => props.theme.colors.texts.boulderGrey"
                    colorName="Boulder Grey"
                    hexColor="#7A7A7A"
                    hint="Subtitles"
                />
                <ColorSwatch
                    colorCode="props => props.theme.colors.texts.black"
                    colorName="Black"
                    hexColor="#000000"
                    hint="Nav Link Hovers"
                />
                <ColorSwatch
                    colorCode="props => props.theme.colors.texts.white"
                    colorName="White"
                    hexColor="#FFFFFF"
                    hint="Nav Link Hovers"
                />
            </section>
            <span className="section__title">Background Colors</span>
            <section>
                <ColorSwatch
                    colorCode="props => props.theme.colors.backgrounds.sand"
                    colorName="Sand"
                    hexColor="#F7F7F5"
                    hint="Body Background, Footer Background"
                />
                <ColorSwatch
                    colorCode="props => props.theme.colors.backgrounds.springSky"
                    colorName="Spring Sky"
                    hexColor="#F9FCFD"
                    hint="Body Background Alternate"
                />
                <ColorSwatch
                    colorCode="props => props.theme.colors.backgrounds.white"
                    colorName="White"
                    hexColor="#FFFFFF"
                    hint="Body Background - Default"
                />
                <ColorSwatch
                    colorCode="props => props.theme.colors.backgrounds.alabaster"
                    colorName="Alabaster"
                    hexColor="#FAFAFA"
                    hint="Body Background - Default"
                />
                <ColorSwatch
                    colorCode="props => props.theme.colors.backgrounds.porcelain"
                    colorName="Porcelain"
                    hexColor="#EDF0F1"
                    hint="FAQ"
                />
                <ColorSwatch
                    colorCode="props => props.theme.colors.backgrounds.altoGrey"
                    colorName="Alto Grey"
                    hexColor="#DBDBDB"
                />
            </section>
        </ColorsStyled>
    );
}
