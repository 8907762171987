export const headlineBoxParams = () => {
    return [
        {
            name: 'text',
            type: 'string',
            required: 'true',
            default: 'n/a',
            description: 'Text to be displayed in box',
        },
    ];
};
