export const logoParams = () => {
    return [
        {
            name: 'className',
            type: 'string',
            required: 'false',
            default: 'empty',
            description:
                'In the event you need to pass a class so that it can be position or styled differently in your component/page',
        },
        {
            name: 'height',
            type: 'string',
            required: 'false',
            default: '43px',
            description: 'Developer defined height for logo component',
        },
        {
            name: 'isLogoWhite',
            type: 'bool',
            required: 'false',
            default: 'false',
            description: 'Changes logo to all white rather than PSW blues',
        },
        {
            name: 'width',
            type: 'string',
            required: 'false',
            default: '202px',
            description: 'Developer defined width for logo component',
        },
    ];
};
