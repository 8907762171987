import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CircleImage from './ui/CircleImage';
import Headline from './ui/Headline';
import BodyCopy from './ui/BodyCopy';

const LargeListStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: left;

    color: ${(props) => props.theme.colors.texts.capeCod};
    font: 1.25rem ${(props) => props.theme.fonts[0]} sans-serif;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 2.125rem;
    .flex-column {
        display: flex;
        flex-direction: column;
        width: 550px;
        margin-left: 73px;
        @media screen and (max-width: 768px) {
            margin-left: 0px;
            width: 307px;
        }
        h3 {
            @media screen and (max-width: 768px) {
                margin: 54px 0 26px 0;
            }
        }
    }

    @media screen and (min-width: 769px) {
        .flex-row {
            display: flex;
            flex-direction: row;
        }
    }
`;
export default function LargeList({
    size,
    imgAlt,
    imgSrc,
    imgTitle,
    isPrimary,
    headlineText,
    bodyCopyText,
}) {
    return (
        <LargeListStyled>
            <div className="flex-row">
                <CircleImage
                    imgAlt={imgAlt}
                    imgSrc={imgSrc}
                    imgTitle={imgTitle}
                    windowSize={size}
                    isPrimary={isPrimary}
                />
                <div className="flex-column">
                    <Headline headlineText={headlineText} />
                    <BodyCopy bodyCopyText={bodyCopyText} />
                </div>
            </div>
        </LargeListStyled>
    );
}
LargeList.propTypes = {
    size: PropTypes.object.isRequired,
    imgAlt: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
    imgTitle: PropTypes.string.isRequired,
    isPrimary: PropTypes.bool.isRequired,
    bodyCopyText: PropTypes.string.isRequired,
    headlineText: PropTypes.string.isRequired,
};
