export const pullQuoteBoxParams = () => {
    return [
        {
            name: 'quoteTitle',
            type: 'string',
            required: 'true',
            default: 'n/a',
            description: 'Title Text to be displayed in pull quote box',
        },
        {
            name: 'quoteContent',
            type: 'string',
            required: 'true',
            default: 'n/a',
            description: 'Content to be displayed in pull quote box',
        },
    ];
};
