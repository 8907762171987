import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Image from './ui/Image';
import ContentBox from './ui/ContentBox';

const ListItemStyled = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
        align-items: center;
    }

    & .listItem--row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: top;
        margin-bottom: 4.125rem;
        &:first-child {
            margin-top: 4.125rem;
        }
        @media screen and (min-width: 768px) {
            width: 745px;
        }
        @media screen and (max-width: 768px) {
            margin: 25px 25px;
        }

        & > .listItem__textBody {
            flex-basis: 75%;
            font-family: ${(props) => props.theme.fonts[0]}, sans-serif;
            text-transform: initial;
            @media screen and (max-width: 768px) {
                flex-basis: 65%;
                header {
                    line-height: 1.75rem;
                }
                article {
                    line-height: 1.875rem;
                }
            }
        }
    }
`;

export default function ListItem(props) {
    let { itemLists, windowSize } = props;

    return (
        <ListItemStyled className="listItem">
            {itemLists &&
                itemLists.map((itemLists, index) => {
                    return (
                        <div className="listItem--row" key={index}>
                            <Image
                                hasLink={false}
                                imgSrc={require('../images/clean-bullets.png')}
                                width="68px"
                                height="66px"
                                className="bullets--green"
                            ></Image>
                            <ContentBox
                                headlineText={itemLists.title}
                                bodyText={itemLists.text}
                                wrapperClass="listItem__textBody"
                            />
                        </div>
                    );
                })}
        </ListItemStyled>
    );
}

ListItem.propTypes = {
    itemLists: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
