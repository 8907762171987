import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ComponentContainerStyled = styled.article`
    margin-bottom: 54px;

    section {
        display: flex;
    }

    .component-name {
        margin-bottom: 24px;
        border-bottom: 4px solid ${(props) => props.theme.colors.texts.shipGrey};
    }

    .description {
        font-size: 14px;

        span {
            font-weight: 700;
        }
    }

    table {
        border-collapse: collapse;
    }

    th,
    td {
        padding: 8px;
        border: 1px solid #333;
    }

    th {
        text-align: left;
    }

    td {
        font: 16px monospace;
    }

    .paramsDesc {
        overflow: auto;
    }
`;

export default function ComponentContainer({
    children,
    componentName,
    description,
    params,
}) {
    let paramList = null;
    if (params) {
        paramList = params.map((param, index) => {
            return (
                <tr key={index}>
                    <td>{param.name}</td>
                    <td>{param.type}</td>
                    <td>{param.required}</td>
                    <td>{param.default}</td>
                    <td>{param.description}</td>
                </tr>
            );
        });
    }
    return (
        <ComponentContainerStyled>
            <h3 className="component-name">{componentName}</h3>
            {children}
            <p className="description">
                <span>Description:</span> {description}
            </p>
            {paramList ? (
                <div className="paramsDesc">
                    <h5>Params:</h5>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Required</th>
                                <th>Default</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>{paramList}</tbody>
                    </table>
                </div>
            ) : null}
        </ComponentContainerStyled>
    );
}

ComponentContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
        .isRequired,
    componentName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    params: PropTypes.array,
};
