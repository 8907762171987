import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import CopyToClipboard from './CopyToClipboard';

const ColorSwatchStyled = styled.article`
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 650px;
    margin-bottom: 54px;

    @media screen and (min-width: 768px) {
        margin-right: 60px;
    }

    .swatch {
        width: 100%;
        height: 176px;
        margin-bottom: 18px;
        background-color: ${(props) => props.hexColor};
    }

    .swatch__info {
        margin: 0;
        line-height: 34px;
    }
`;

export default function ColorSwatch({ colorCode, colorName, hexColor, hint }) {
    return (
        <ColorSwatchStyled hexColor={hexColor}>
            <CopyToClipboard stringToCopy={colorCode} />
            <div className="swatch"></div>
            <p className="swatch__info">{colorName}</p>
            <p className="swatch__info">{hexColor}</p>
            {hint ? <p className="swatch__info">{hint}</p> : null}
        </ColorSwatchStyled>
    );
}

ColorSwatch.propTypes = {
    colorCode: PropTypes.string.isRequired,
    colorName: PropTypes.string.isRequired,
    hexColor: PropTypes.string.isRequired,
    hint: PropTypes.string,
};
