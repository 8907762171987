export const infoCardParams = () => {
    return [
        {
            name: 'copy',
            type: 'string',
            required: 'true',
            default: 'n/a',
            description: 'Text to populate the alt attribute of the img tag',
        },
        {
            name: 'ctaLink',
            type: 'string',
            required: 'false',
            default: 'n/a',
            description: 'Directory location of the needed image',
        },
        {
            name: 'ctaText',
            type: 'string',
            required: 'false',
            default: 'n/a',
            description: 'Text to populate the title attribute of the img tag',
        },
        {
            name: 'imgObj',
            type: 'obj',
            required: 'false',
            default: 'n/a',
            description:
                'Should contain imgSrc, imgAlt and imgTitle. All properties are required if the object is passed',
        },
        {
            name: 'isCTAExternal',
            type: 'bool',
            required: 'false',
            default: 'false',
            description:
                'Text to populate the title that displays under the image',
        },
        {
            name: 'isCTALink',
            type: 'bool',
            required: 'false',
            default: 'true',
            description:
                'Object that gives width and height of the current browser state',
        },
        {
            title: 'title',
            type: 'string',
            required: 'true',
            default: 'n/a',
            description: 'Info card title displays above copy',
        },
    ];
};
